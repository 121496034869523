/*
Theme Name: theaney
*/
$orange: #ef812f;
$grey: #F7F8FA;
@mixin clearfix {
    &:after,
    &:before {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}

*, *::after, *::before {
    box-sizing: border-box;
}

html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: always;
    -webkit-tap-highlight-color: rgba(black,0);
}

html, body {
    height: 100%;
    // overflow-x: hidden;
}

article,
body,
div,
main,
p {
    font-family: 'Lato', Arial, sans-serif;
    // font-weight: 300;
    // box-sizing: border-box;
}

body {
    // background: url("./img/homeBack.png") center center /cover no-repeat;
    // width: 100vw;
    // min-height: 100vh;
    background-attachment: fixed!important;
}

ul {
    margin: 0;
}

a {
    text-decoration: none;
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

// a.overlay {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     left: 0;
//     top: 0;
// }

.clear {
    height: 0 !important;
    width: 100%;
    clear: both;
    float: none !important;
}

.hidden {
    width: 0.1px;
    height: 0.1px;
    margin: 0;
    padding: 0;
    font-size: 0.1px;
}

.screen-reader-text {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

header {
    background: rgba(0,0,0,0.75);
    height: 75px;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;

    .logo {
        margin: 20px 0 0 20px;
        width: 34px;
        height: 31px;
        overflow: hidden;
        display: inline-block;

        &.big {
            display: none;
        }

        img {
            min-width: 100%;
            width: 100%;
            height: auto;
        }
    }

    .menuButton {
        text-transform: uppercase;
        color: $orange;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 20px;

        .icon {
            background: url("./img/downArrow.png") center center /contain no-repeat;
            width: 22px;
            height: 10px;
            margin: 5px auto auto;
        }
    }

    .anniversary {
        position: absolute;
        width: 80px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        img {
            width: 100%;
        }
    }
}

.menuWrap.mob {
    height: auto;
    min-height: 100vh;
    text-align: center;
    width: 100vw;
    color: $orange;
    background: rgba(0,0,0,0.75);
    position: fixed;
    z-index: 900;
    will-change: transform;
    transform: translateY(-100%);
    transition: all 0.3s linear;
    ul {
        list-style: none;
        padding-left: 0;

        li {
            // margin: 30px 0;
            font-size: 1.4em;

            a {
                color: inherit;
                text-decoration: none;
                display: block;
                padding: 15px 0;
            }
        }
    }
}

.menuWrap.desk {
    display: none;
}

.menuOpen {
    .menuWrap {
        // top: 75px;
        transform: translateY(75px);
    }

    .menuButton {
        .text {
            display: none;
        }

        .icon {
            background-image: url("./img/menuClose.png");
            margin: 0 15px 0 0;
            height: 22px;
        }
    }
}

.home {
    .logo.small {
        display: block;
    }

    .logo.big {
        display: none;
    }
}

.home {
    .slide,
    .slideWrap,
    main {
        height: 100vh;
        width: 100vw;

        .text {
            color: white;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.2em;
            margin: 100px 50px;
            max-width: 250px;
            // &:before {
            //     content: '';
            //     display: block;
            //     background: url(./img/quoteOpen.png) center center /contain no-repeat;
            //     width: 100%;
            //     height: 10px;
            // }
            // &:after {
            //     content: '';
            //     display: block;
            //     background: url(./img/quoteClose.png) center center /contain no-repeat;
            //     width: 100%;
            //     height: 10px;
            // }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(161deg, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.5) 25%,rgba(0,0,0,0)35%,rgba(0,0,0,0) 100%);
        }
    }
}

%blurBox {
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: white;
    padding: 20px;
    text-shadow: 0 1px 2px rgba(black, 0.5);
}

article.aboutArticle {
    @extend %blurBox;
    max-width: 500px;
}

main {
    // padding-top: 75px;
}

body.page:not(.home),
body.post-type-archive-clients {
    background: url("./img/back.jpg") center center /cover no-repeat;

    .logo.big {
        display: none;
    }

    h1 {
        color: #ffffff;
        // font-weight: 300;
        font-weight: 400;
        font-size: 2rem;
        margin: 0;
        text-shadow: 0 1px 2px rgba(black, 0.32);
    }
}

body.post-type-archive-clients {
    & h1 {
        color: #404040;
        text-shadow: none;
    }
}

body.blog,
body.page:not(.home),
body.post-type-archive-clients,
body.single-clients,
body.single-post {
    & main {
        padding-top: 75px;
        // margin-top: 75px;
        // position: relative;
        // height: 100vh;

        @media screen and (min-width: 1024px) {
            padding-top: 110px;
            // margin-top: 110px;
        }
    }
}

.article-wrapper {
    position: absolute;
    // width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // overflow-x: hidden;
    // overflow-y: scroll;
    min-height: min-content;

    .page & {
        top: 75px;
    }
}

.home .slideWrap .slide {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#fullpage {

    & .fp-slidesContainer {
        width: 100%!important;
        transform: none!important;
    }

    & .slide {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%!important;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
        transition: all 1s ease-in-out;

        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;

        &.active {
            visibility: visible;
            z-index: 1;
            opacity: 1;
        }

        // position: absolute;
        // display: flex;
        // align-content: center;

        & .inner {
            @extend %blurBox;
            max-width: 300px;
            margin: auto;
            text-align: center;
            // text-transform: uppercase;
            // font-size: 1.2em;

            @media screen and (min-width: 1024px) {
                max-width: 400px;
                font-size: 1.3rem;
            }
        }
    }

    & .fp-section {
        height: 100%!important;
    }
}

// .inner {
//     position: relative;
//     z-index: 1;
//     padding: 20px;
//     max-width: 280px;
//     margin: auto;
//     color: white;
//     text-align: center;
//     text-transform: uppercase;
//     font-size: 1.2em;
//     text-shadow: 0 1px 2px rgba(black, 0.5);
//
//     display: table;
//     table-layout: fixed;
//     vertical-align: middle;
//
//     @media screen and (min-width: 1024px) {
//         max-width: 360px;
//     }
// }

.blur-bg {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -2;
    overflow: hidden;

    background-position: center center;
    background-size: cover;
    // background-size: 550%;
    background-repeat: no-repeat;
    background-attachment: fixed;

    .is-android & {
        position: fixed;
        top: 0px;
        left: 0px;
        right: auto;
        bottom: auto;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
    }



    .slick-active & {

        // background-attachment: fixed!important;
    }

    .page:not(.home) & {
        background-attachment: fixed!important;
    }

    @media screen and (min-width: 1024px) {
        // background-size: 425%;
    }

    &-default {
        filter: blur(6px);
    }

    &-fallback {
        display: none;
    }
}

.no-cssfilters {
    & .blur-bg-default {
        display: none;
    }
    & .blur-bg-fallback {
        display: block;
    }
}

.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: rgba(black, 0.35);
}

.quote {
    position: relative;
    height: 3px;
    background-color: #ef812f;

    &::after,
    &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 13px;
        background-color: #ef812f;
        display: block;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    &-open {
        margin: 0 0 24px;

        &::after,
        &::before {
            top: 0;
        }
    }

    &-close {
        margin: 24px 0 0;

        &::after,
        &::before {
            top: -10px;
        }
    }
}
// TRACK RECORD
body.page-id-49 {
    main {
        .lowerWrap {
            // margin-top: 100px;
            // margin-top: 75px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            .recordBox {
                width: calc(50% - 0.5px);
                float: left;
                margin-bottom: 1px;
                position: relative;
                font-size: 0.8rem;
                transition: background-size ease 300ms;

                & a {
                    // position: relative;
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                &:empty {
                    margin: 0!important;
                    padding: 0!important;
                }
                @media screen and (max-width: 600px) {
                    width: 100%;
                    float: none;
                    margin-right: 0;
                }

                &.duo {
                    width: calc(66.66% - 1.5px);

                    article {
                        padding: 0 20px;
                        color: white;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;

                        p {
                            text-transform: uppercase;
                            line-height: 22px;
                        }
                    }
                    &:not(.heading) {
                        &:after {
                            padding-bottom: 50%;
                        }
                    }

                }

                &.heading {
                    width: 100%;
                    float: none;
                    color: #ffffff;
                    padding: 16px;

                    &:after {
                        // padding-bottom: 10%;
                        // padding-bottom: calc(0.67em + 16px);
                    }
                    @media screen and (min-width: 1024px) {
                        padding-left: 0;
                    }
                }

                &:not(.heading) {
                    &:after {
                        padding-bottom: 100%;
                        content: '';
                        display: block;
                    }
                }

                &:nth-of-type(even) {
                    margin-right: 1px;
                }

                p {
                    text-transform: uppercase;
                    margin: 0;
                }

                &:active,
                &:focus,
                &:hover {
                    background-size: auto 130% !important;

                    .hoverBox {
                        background: rgba(0,0,0,0.1);
                    }
                }

                .hoverBox {
                    transition: background ease 300ms;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background: rgba(0,0,0,0.4);
                    color: white;
                    text-align: center;
                    padding: 15px;

                    .more {
                        font-style: italic;
                        position: absolute;
                        bottom: 10px;
                        left: 50%;
                        transform: translateX(-50%);

                        &:before {
                            background: url("./img/moreOpen.png") center center /contain no-repeat;
                            content: '';
                            display: block;
                            width: 70px;
                            height: 8px;
                            margin: auto;
                        }

                        &:after {
                            background: url("./img/moreClose.png") center center /contain no-repeat;
                            content: '';
                            display: block;
                            width: 70px;
                            height: 8px;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}
// CLIENTS
body.page-id-8,
body.post-type-archive-clients {
    main {
        article {
            padding: 20px;

            @media screen and (min-width: 600px) {
                padding: 40px;
            }
        }

        .clientWrap {
            // background: white;

            .clientWrapInner {
                max-width: 1100px;
                margin: auto;
                padding:0px 20px;
                background-color: #ffffff;
            }

            .clientBox {
                float: left;
                width: calc(50% - 0.5px);
                position: relative;
                margin-bottom: 1px;

                &:nth-of-type(odd) {
                    margin-right: 1px;
                }

                &:after {
                    padding-bottom: 100%;
                    content: '';
                    display: block;
                }
            }
        }
    }
}
// CLIENT SINGLE
body.single-clients {
    & .client-single-page {
        position: absolute;
        top: -30px;
        left: 0;
        right: 0;
        min-height: calc(100% + 30px);
        background-attachment: fixed;

        & .articleInner {
            padding: 105px 0 0 !important;

            & .imageWrap {
                margin: auto;
                padding: 20px 20px 20px;

                & img {
                    width: 320px;
                }

                & .content {
                    position: relative;

                    @media screen and (max-width: 768px) {
                        padding-top: 60px
                    }

                    & .closeButton {
                        height: 20px;
                        width: 20px;
                        background: url("./img/closeButton.png") center center /contain no-repeat;
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        cursor: pointer;
                        z-index: 2;

                        & a {
                            display: block;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    & main {
        & h2 {
            color: white;
            font-weight: 300;
            font-size: 1rem;
            padding: 10px 20px;
        }

        & .imageWrap {
            padding: 20px;

            & .content {
                background: rgba(0,0,0,0.8);
                color: white;
                padding: 20px 25px;

                & .intro {
                    margin: 20px 0;
                    text-transform: uppercase;
                }
            }
        }
    }
}
// SERVICES
body.page-id-12:not(.home) {
    & h1 {
        margin: 0.67em 0;
    }

    & main {
        & article {
            padding: 20px;

            & .content {
                text-transform: uppercase;
                color: white;
                // padding: 1px 20px;
                position: relative;
                // &:before {
                //     content: '';
                //     display: block;
                //     background: url(./img/quoteOpen.png) center center /100% no-repeat;
                //     width: calc(100% + 20px);
                //     height: 10px;
                //     position: absolute;
                //     top: 0;
                //     left: 50%;
                //     transform: translateX(-50%);
                // }
                // &:after {
                //     content: '';
                //     display: block;
                //     background: url(./img/quoteClose.png) center center /100% no-repeat;
                //     width: calc(100% + 20px);
                //     height: 10px;
                //     position: absolute;
                //     bottom: 0;
                //     left: 50%;
                //     transform: translateX(-50%);
                // }
            }
        }

        & .serviceWrap {
            // background: $grey;
            // padding: 15px 20px;
            padding: 0;

            & h2 {
                // font-weight: 300;
            }

            & .service {
                margin: 40px 0;
            }
        }

        & .serviceInner {
            // @include clearfix;
            margin: 20px;
            padding: 20px;
            background-color: $grey;
        }
    }
}
// DISPOSALS
body.page-id-14 {
    main {
        max-width: 1000px;
        margin: auto;

        > h1 {
            padding: 40px 20px;
        }
    }

    article {
        width: 100%;
        top: 190px;
        background: rgba(0, 0, 0, 0.8);
        padding: 20px;

        h2 {
            font-weight: 300;
            color: $orange;
            text-transform: uppercase;
            margin: 10px 0 20px;
            max-width: 520px;
        }

        table {
            color: white;
            width: 100%;
            padding: 15px 0;

            &.desk {
                display: none;
            }

            th {
                font-weight: bold;
                text-transform: uppercase;
                text-align: left;
                width: 6%;
            }

            td {
                width: 30%;

                &:last-of-type {
                    width: 2%;
                    text-align: center;

                    .icon {
                        height: 20px;
                        width: 20px;
                        background: url("./img/download.png") center center /contain no-repeat;
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
    }

    .bottomText {
        color: $orange;
        border-top: 1px white solid;
        padding: 20px;
        background: rgba(0,0,0,0.8);

        p {
            margin: 0;
        }
    }
}
// CONTACT
body.page-id-18 {
    & .address {
        padding: 20px;
        color: white;

        & a {
            color: white;
            font-size: 0.8em;
        }
    }

    & .map {
        height: 300px;

        & iframe {
            height: 300px;
            width: 100%;
        }
    }

    & .team {
        background: white;
        padding: 20px 0;

        & .teamMemb {
            float: left;
            padding: 50px;
            position: relative;
            width: 100%;
            text-align: center;

            & .image {
                border-radius: 50%;
                margin: auto;

                &:after {
                    padding-bottom: 100%;
                    display: block;
                    content: '';
                }
            }

            & .name h2 {
                font-weight: 300;
                font-size: 1rem;
                margin-bottom: 2px;
            }

            & .email {
                font-size: 0.85em;
                // overflow: hidden;

                & a {
                    text-decoration: none;
                    color: black;
                }
            }

            & .qualification {
                font-size: 0.8rem;
                margin-bottom: 15px;
                height: 16px;
            }

            & .linkedIn {
                background: url("./img/linked.png") center center /contain no-repeat;
                display: block;
                height: 15px;
                width: 15px;
                margin-top: 5px;
            }

            & .description {
                display: none;
            }

            &.open:after {
                width: 30px;
                height: 30px;
                content: '';
                display: block;
                transform: rotate(45deg) translateX(-50%);
                position: absolute;
                bottom: -28px;
                left: 50%;
                background: $orange;
            }
        }

        & .teamInfo {
            clear: both;
            background: $orange;
            padding: 0 20px;
            position: relative;

            & p {
                padding: 20px 0;
            }
        }
    }
}
// NEWS
.blog {
    & h1 {
        color: white;
        font-weight: 300;
        margin: 0;
        padding: 40px 20px;
    }

    & .newsWrap {
        // background: white;
        // margin-top: 60px;
        & .news-inner-wrap {
            padding: 20px;
            background-color: white;
        }

        & .newsItem {
            background-color: white;
            position: relative;
            padding: 15px 0px;

            & .mobile-only {
                display: none!important;
                @media screen and (min-width: 1024px) {
                    display: none;
                }
            }

            & a {
                color: inherit;
            }

            &:not(:last-of-type) {
                border-bottom: 1px #373736 solid;
            }

            &:hover {
                & .left {
                    & .intro {
                        color: black;
                    }
                }
            }

            & .left {
                text-transform: uppercase;
                width: 60%;
                float: left;
                font-size: 0.9rem;

                & .intro {
                    color: $orange;
                    margin: 10px 0;
                }
            }

            & .middle {
                // display: none;
            }

            & .right {
                width: 40%;
                float: left;
                padding: 20px 0 20px 20px;
                text-align: center;

                & img {
                    max-width: 100%;
                    height: auto;
                    padding: 10px 10px 20px;

                    &.desktop {
                        display: none;

                        @media screen and (min-width: 1024px) {
                            display: inline-block;
                        }
                    }

                    &.mobile {
                        @media screen and (min-width: 1024px) {
                            display: none;
                        }
                    }
                }

                & a.more {
                    display: none;
                }
            }

            @media screen and (max-width: 400px) {
                & .right {
                    & img {
                        max-width: 90%!important;
                    }
                }
            }

            @media screen and (max-width: 767px) {
                & .left, & .middle, & .right {
                    width: 100%;
                    float: none;
                }
                & .right {
                    padding-left: 0;
                }
                & .left {
                    & a.more:not(.mobile-only) {
                        display: none;
                    }
                }
                & .right {
                    & a.more {
                        display: block;
                    }

                    & img {
                        max-width: 70%;
                    }
                }
            }
        }
    }
}
// SINGLE
.single-post {
    .singleWrap {
        margin-top: 30px;

        & .singleWrapInner {
            position: relative;
            margin: auto;
            max-width: 95%;
            padding: 60px 20px 20px;
            background-color: white;

            & .close {
                position: absolute;
                top: 15px;
                right: 15px;
                background: url("./img/closeButton.png") center center /contain no-repeat;
                float: none;
                height: 20px;
                width: 20px;

                & a {
                    display: block;
                    height: 100%;
                }
            }
        }

        .date,
        .intro {
            text-transform: uppercase;
        }

        .intro {
            color: $orange;
            margin-top: 15px;
        }
    }

    .image {
        &.desk {
            display: none;
        }

        &.mob {
            display: block;
            padding-top: 20px;
        }
        // width: 100vw;
        left: 0;
        // margin-left: -20px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .logo {
        display: none;
    }
}
@media screen and (min-width: 400px) {
    body.page-id-18 {
        .team {
            .teamMemb {
                width: 50%;
                padding: 15px;
                text-align: left;
            }
        }
    }
}
@media screen and (min-width: 456px) {
    body.post-type-archive-clients {
        & main {
            & .clientWrap {
                & .clientBox {
                    width: calc(33.33% - 1px);
                    border-bottom: 2px solid $grey;
                    margin: 0 !important;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    body.post-type-archive-clients {
        & main {
            & .clientWrap {
                & .clientBox {
                    width: calc((100%/4) - 1px);
                }
            }
        }
    }

    body.page-id-18 {
        main {
            padding: 110px 0 0 50px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .left {
            width: 200px;
            float: left;

            .address {
                padding: 0 0 50px;
                max-width: 1200px;
                margin: auto;
            }
        }

        .right {
            width: calc(100% - 200px);
            float: left;

            .team {
                .teamInner {
                    max-width: 1200px;
                    margin: auto;
                }
            }

            .map {
                height: 400px;

                iframe {
                    height: 400px;
                }
            }
        }

        .clear {
            width: 100%;
            clear: both;
            height: 0;
            float: none;
        }
    }

    body.blog {
        main {
            h1 {
                padding: 50px 0;
                max-width: 1000px;
                margin: auto;
            }

            .newsWrap {
                // padding: 20px;
                margin-top: 0;

                .news-inner-wrap {
                    margin: auto;
                    max-width: 1000px;
                }

                .newsItem {
                    padding: 20px;
                    // margin: auto;
                    // max-width: 1000px;
                    .left {
                        width: 30%;
                        padding: 20px 20px 20px 0;
                        font-size: 1rem;
                    }

                    .right {
                        width: 20%;
                        padding: 20px 0 20px 30px;
                    }

                    .middle {
                        display: block;
                        width: 50%;
                        float: left;
                        padding: 20px;
                    }
                }
            }
        }
    }
}

.slideWrap {
    & .slide {
        position: relative;
    }

    & .inner {
        // margin: auto;
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        & .text {
            position: relative;
            z-index: 0;
            padding: 40px;
            max-width: 280px;
            text-shadow: 0 1px 2px rgba(black, 0.5);

            &::after,
            &::before {
                height: 16px;
            }

            &::after {
                margin-top: 1em;
            }

            &::before {
                margin-bottom: 1em;
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    body.post-type-archive-clients {
        & main {
            & .clientWrap {
                & .clientBox {
                    width: calc((100%/6) - 1px);
                }
            }
        }
    }

    body.page-id-18 {
        & .right {
            & .team {
                & .teamMemb {
                    width: 25%;
                }
            }
        }
    }

    .address,
    .map,
    .team {
        opacity: 0;
        margin-top: 15px !important;
        transition: all 300ms linear;
    }

    .newsWrap,
    .clientWrap,
    .recordBox,
    .serviceWrap,
    .policyWrap
    article:not(.aboutArticle) {
        opacity: 0;
        margin-top: 30px !important;
        transition: all 300ms linear;
    }

    body.go {
        .address, .clientWrap,
        .map, .newsItem,
        .recordBox, .serviceWrap,
        .newsWrap, .team,
        article:not(.aboutArticle) {
            opacity: 1;
            margin-top: 0;
        }
    }

    main {
        // padding-top: 110px;
        padding-top: 1px;

        & article:not(.aboutArticle) {
            max-width: 1200px;
            margin: auto;
            padding: 50px !important;
        }
    }

    & body.single-clients {
        & main {
            & article {
                max-width: none;

                & .articleInner {
                    max-width: 1200px;
                    margin: auto;
                    padding: 50px !important;
                }
            }
        }
    }

    & header {
        height: 110px;

        & .logo {
            height: 70px;
            width: 76px;
            margin: 20px 0 0;

            &.small {
                display: inline-block;
            }
        }

        & .headerInner {
            display: flex;
            max-width: 1000px;
            margin: auto;
            position: relative;
            height: 110px;

            & .menuWrap.desk {
                line-height: 110px;
                display: inline-block;
                width: calc(100% - 220px);
                margin-bottom: 5px;

                & ul {
                    list-style: none;
                    padding-left: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;

                    & li {
                        display: inline-block;

                        &:hover {
                            & a {
                                color: white;
                            }
                        }

                        & a {
                            color: $orange;
                            text-decoration: none;
                        }

                        &.current-menu-item,
                        &.current_page_parent {
                            a {
                                color: white;
                            }
                        }
                    }
                }
            }

            & .menuButton,
            & .menuWrap.mob {
                display: none;
            }

            & .anniversary {
                right: 0;
                transform: translateY(-50%);
                left: auto;
            }
        }
    }

    body.post-type-archive-clients,
    body.single-clients {
        & header {
            & .headerInner {
                & .menuWrap.desk {
                    & ul {
                        & li {
                            &.menu-item-26 {
                                & a {
                                    color: $orange;
                                }
                            }

                            &.menu-item-82 {
                                & a {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body.page-id-18 {
        & .map {
            height: 500px;

            & iframe {
                height: 500px;
            }
        }
    }

    .home {
        & header {
            top: calc(100vh - 110px);
            transition: all 300ms linear;

            & .logo {
                &.small {
                    display: inline-block;

                    & img {
                        display: none;
                    }
                }
            }
        }

        header.go {
            top: 0;
        }

        .sideWrap,
        .slide,
        main {
            & .text {
                margin: 200px 100px;
            }
        }
        // .slideWrap .inner {
        //     max-width: 1350px;
        //     margin: auto;
        // }
        .slideWrap {
            & .slide {
                position: relative;

                display: table;
                table-layout: fixed;
                vertical-align: middle;
            }

            & .inner {
                // margin: auto;
                // position: absolute;
                // top: 0;
                // left: 0;
                // right: 0;
                // bottom: 0;
                // display: flex;
                // justify-content: center;
                // align-items: center;

                & .text {
                    position: relative;
                    z-index: 0;
                    padding: 40px;
                    max-width: 500px;
                    text-shadow: 0 1px 2px rgba(black, 0.5);

                    &::after,
                    &::before {
                        height: 16px;
                    }

                    &::after {
                        margin-top: 1em;
                    }

                    &::before {
                        margin-bottom: 1em;
                    }
                }
            }
        }

        & main {
            padding-top: 0;

            & .logo.big {
                display: block;
                position: absolute;
                width: 1000px;
                max-width: 1000px;
                margin: auto;
                z-index: 999;
                left: 50%;
                transform: translateX(-50%);
                top: 70px;

                & img {
                    height: auto;
                }
            }
        }
    }

    .home.switch {
        & header {
            & .logo.small {
                & img {
                    display: block;
                }
            }
        }

        & main {
            & .logo.big {
                display: none;
            }
        }
    }

    body.single-clients {
        article {
            & a.back {
                display: none;
            }

            & .imageWrap {
                background: transparent;
                max-width: 700px;
                padding: 60px 20px 20px;
            }
        }
    }

    body.page-id-49 {
        main {
            & .lowerWrap {
                max-width: 1000px;
                & .recordBox {
                    width: calc(33.33% - 1.5px);
                    margin: 0.5px;
                    font-size: 1rem;

                    & .hoverBox {
                        padding: 50px;

                        & .more {
                            bottom: 35px;
                            font-size: 0.8rem;
                        }
                    }
                }
            }
        }
    }

    body.page-id-12 {
        & main {
            & article {
                & .content {
                    font-size: 1.4rem;
                    max-width: 300px;
                }
            }

            & .serviceWrap {
                padding: 0 20px;

                & .serviceInner {
                    max-width: 1100px;
                    margin: auto;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    background-color: $grey;
                    padding: 15px 20px;

                    & .service {
                        width: (100%/3);
                        width: calc(100%/3);
                        padding: 20px 15px;
                        margin: 0;

                        &:nth-of-type(3n+1) {
                            padding-left: 0px;
                        }
                        &:nth-of-type(3n+3) {
                            padding-right: 0px;
                        }

                        &:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)):not(:nth-last-of-type(3)) {
                            border-bottom: 2px solid rgba(0,0,0,0.2);
                        }

                        & h2 {
                            font-size: 18px;
                            letter-spacing: -0.25px;
                            // text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    body.page-id-14 {
        main {
            h1 {
                padding: 50px 0;
            }

            article {
                table {
                    &.mob {
                        display: none;
                    }

                    &.desk {
                        display: block;

                        tr {
                            td {
                                padding: 10px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    body.blog {
        main {
            h1 {
                padding: 50px 0;
                max-width: 1000px;
                margin: auto;
            }

            .newsWrap {
                // padding: 20px;
                margin-top: 0;

                .news-inner-wrap {
                    margin: auto;
                    max-width: 1000px;
                }

                .newsItem {
                    padding: 20px;
                    // margin: auto;
                    // max-width: 1000px;
                    .left {
                        width: 30%;
                        padding: 20px 20px 20px 0;
                        font-size: 1rem;
                    }

                    .right {
                        width: 20%;
                        padding: 20px 0 20px 30px;
                    }

                    .middle {
                        display: block;
                        width: 50%;
                        float: left;
                        padding: 20px;
                    }
                }
            }
        }
    }

    body.single {
        main {
            .singleWrap {
                .singleWrapInner {
                    // padding: 100px 0 50px;
                    max-width: 1000px;

                    .textWrap {
                        float: left;
                        width: 54%;
                        padding: 0 30px;
                    }

                    .image {
                        float: left;
                        width: 30%;
                        margin: 0;
                        padding-right: 20px;

                        &.mob {
                            display: none;
                        }

                        &.desk {
                            display: block;
                        }
                    }

                    .logo {
                        display: block;
                        width: 16%;
                        float: left;
                        padding-left: 20px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

.page-numbers {
    font-size: 15px;
}

.page-numbers.current {
    color: #222;
}

.page-numbers .dots {
    letter-spacing: 1px;
}

a.page-numbers {
    font-size: 14px;
    color: #3888ff;
}

.pagination {
    & .nav-links {
        text-align: center;

        & .page-numbers {
            position: relative;
            display: inline-block;
            color: #ef812f;
            padding: 0 6px;
            margin: 0 10px;
            transition: all 0.25s ease-in-out;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            &::after,
            &::before {
                display: block;
                position: absolute;
                top: 0;
            }

            &::before {
                content: '[';
                left: -2px;
            }

            &::after {
                content: ']';
                right: -2px;
            }

            &.current {
                color: darken(#ef812f, 10%);
                font-weight: 700;
            }

            &:not(.current) {
                &:hover {
                    color: darken(#ef812f, 5%);
                    text-decoration: underline;
                }
            }
        }
    }
}

.no-download {
    opacity: 0.65;
    filter: grayscale(0.5);
    transform: scale(0.9);
    cursor: not-allowed;
}

.read-more {
    & a {
        color: inherit;
        text-decoration: underline;
        transition: color 0.25s;

        &:hover {
            color: #ef812f;
        }
    }
}


//PRIVACY POLICY
.policyWrap {
    padding: 20px;
    background-color: #ffffff;
    margin: auto;
    max-width: 1000px;
    margin-top: 30px!important;

}

//FOOTER
.site-footer {
    background: rgba(0,0,0,.75);
    color: #ffffff;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    margin-top: auto;
    font-size: 0.8125rem;
    font-family: "acumin-pro", arial, sans-serif;

    p {
        margin: 3px 0;
    }

    a {
        text-decoration: none;
        color: #ffffff;
    }

}

//FOOTER FIX
body {
    display: flex;
    flex-direction: column;
}
main {
    padding-bottom: 4em;
}

@media only screen and (min-width: 768px) {
    article.aboutArticle {
        position: absolute;
        top: 55px;
        right: 50px;
    }
}

body.home {
    @media only screen and (min-width: 768px) {
        .fp-tableCell {
            .inner {
                position: absolute !important;
                top: 130px;
                right: 50px;
            }
        }
    }

    .fullpage-wrapper {
        height: 100vh !important;
    }
}

.clientWrap {
    .clientWrapInner {
        display: grid;
        grid-template-columns: repeat(6, 1fr);

        @media only screen and (max-width: 991px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media only screen and (max-width: 767px) {
            grid-template-columns: repeat(3, 1fr);
        }

        .clientBox {
            width: 100% !important;

            @media only screen and (min-width: 992px) {
                &:nth-child(25) {
                    grid-column-start: 2;
                }
            }

            @media only screen and (max-width: 767px) {
                &:nth-child(28) {
                    grid-column-start: 2;
                }
            }
        }
    }
}
